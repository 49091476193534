import { useSelector,useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import { getActionByPage, getActionByType } from "../../functions/functions_actions";

const Main = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const settings = useSelector((store) => store.settings);
  const [disabled, setDisabled] = useState(true);
  const [datos, setDatos] = useState({
      nombre:'',
      email:'',
      text:'',
     })
    const actions = useSelector(store => store.actions.actions)


    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  useEffect(() => {
    // fbq('track', 'Lead');
  },[])


  return (
    <main class="main main-inner main-contacts bg-contacts bg-zoom-in-out" data-stellar-background-ratio="0.6">
        <div class="container">
            <header class="main-header">
                <h1>Contactanos</h1>
            </header>
        </div>

        {/* <!-- Lines --> */}

        <div class="page-lines">
            <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </main>
    )
};

export default Main;
