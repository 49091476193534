import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
// import SimpleReactLightbox from 'simple-react-lightbox';
import './index.scss';
// import './../node_modules/animate.css/animate.css';

import './assets/styles/global/modules/media-push/_featuredtop.scss';
import './assets/styles/global/modules/media-push/_counterpopup.scss';
import './assets/styles/global/modules/media-push/_flapbtn.scss';
import './assets/styles/global/modules/media-push/_squarepopup.scss';
import './assets/styles/global/modules/media-push/_topalert.scss';



import {BrowserRouter as Router,Switch,withRouter,Route} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
