import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNoveltiesAction } from '../../redux/noveltiesDucks'
import { getTextShort,getDateTime,getFeatured,orderNovelties,notFeatured } from '../../functions/functions_novelties'

import { Link } from 'react-router-dom'

const Month = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
}

const Blogs = () => {

    const dispatch = useDispatch()
    const data = useSelector((store) => store.novelties.novelties);
    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        dispatch(getNoveltiesAction())
    }, [])

    const getThree = (data) => {
        // Agarro los primeros 3 activos por position
        return data.slice(0,3)
    }

    const formatNov = (novArray) => {
        const featured = getFeatured(novArray);
        if (featured?.length < 3) {
            let featuredFirst = [
                ...featured,
                ...orderNovelties(notFeatured(novArray))
            ];
            return getThree(featuredFirst);
        } else  {
            return getThree(featured)
        }
    }

    return data ? (
        <section className="blog-list blog-home">
            <div className="container ">
                <header className="section-header ">
                    <h2 className="section-title "><span className="text-primary ">RED</span> Noticias</h2>
                </header>
                <header className="section-header wow fadeInRight ">
                    <strong className="fade-title-right ">Novedades</strong>
                </header>
                <div className="section-content d-block-mobile" style={{display:"flex",justifyContent:"space-between"}}>
                    {formatNov(data).map((item,index) => (
                        <article key={index} className="blog col-md-4 col-12">
                            <div className="row ">
                                <div className="blog-thumbnail col-md-5 ">
                                    <div className="blog-thumbnail-bg col-md-5 visible-md visible-lg " style={{backgroundImage: "url(" + item.image + ")"}}></div>
                                    <div className="blog-thumbnail-img visible-xs visible-sm "><img alt=" " className="img-responsive " src={item.image}/></div>
                                </div>
                                <div className="blog-info col-md-7 " style={{padding: "1em "}}>
                                    <h3 className="blog-title ">
                                        <a href=" ">{getTextShort(item.title,50)}</a>
                                    </h3>
                                    <p>{getTextShort(item.headline,100)}</p>
                                    <div className="blog-meta ">
                                        <div className="time ">{getDateTime(item.date_published)}</div>
                                    </div>
                                    <div className="text-right "><Link to={settings.base_url + "novedades/" + item.id + "/" + item.slug} className="read-more ">Leer más</Link></div>
                                </div>
                            </div>
                        </article>   
                ))}
                </div>
                    <div className="section-content text-center ">
                        <Link to="novedades" className="btn btn-gray ">Ver todas la novedades</Link>
                    </div>
            </div>
        </section>
    ):
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Blogs
