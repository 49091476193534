const Experience = () => {
    return (
        <section class="experience section" style={{marginTop: "10.1em"}}>
            <div
            class="text-parallax"
            data-stellar-background-ratio="0.85 "
            style={{backgroundImage: "url('img/bg/text-2.jpg')"}}
            >
            <div class="text-parallax-content" style={{fontSize: "10em"}}>30</div>
            </div>

            <h4 class="experience-info wow fadeInRight max-width-450s">
            <span class="text-primary">años </span><br />
            de trayectoria ininterrumpida.
            </h4>

            <div
            class="text-parallax"
            data-stellar-background-ratio="0.85 "
            style={{backgroundImage: "url('img/bg/text-2.jpg')"}}
            >
            <div class="text-parallax-content" style={{fontSize: "10em"}}>100</div>
            </div>
            <h4 class="experience-info wow fadeInRight max-width-450s">
            <span class="text-primary">% </span><br />
            dedicado al Real Estate,<br />
            desde la fundación de la compañía a hoy.
            </h4>

            <div
            class="text-parallax"
            data-stellar-background-ratio="0.85 "
            style={{backgroundImage: "url('img/bg/text-2.jpg')"}}
            >
            <div class="text-parallax-content" style={{fontSize: "10em"}}>+1</div>
            </div>
            <h4 class="experience-info wow fadeInRight max-width-450s">
            <span class="text-primary">millón de m2 </span><br />
            entregados a nuestros clientes.
            </h4>

            <div
            class="text-parallax"
            data-stellar-background-ratio="0.85 "
            style={{backgroundImage: "url('img/bg/text-2.jpg')"}}
            >
            <div class="text-parallax-content" style={{fontSize: "10em"}}>
                +1.000
            </div>
            </div>
            <h4 class="experience-info wow fadeInRight max-width-450s">
            <span class="text-primary">millones de dólares</span><br />
            administrados en distintos proyectos.
            </h4>
      </section>
    )
}

export default Experience