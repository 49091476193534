import React , {useEffect} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentAction } from '../redux/developmentsDucks';
import { getUnitsDevelopmentAction } from '../redux/developmentsDucks';

// import Main from '../components/emprendimiento/main'
import Data from '../components/emprendimiento/data'
// import Projects from '../components/emprendimiento/projects'
import DevImgGallery from '../components/emprendimiento/devImgGallery'
import Details from '../components/emprendimiento/details'
import Video from '../components/emprendimiento/video'
import Unities from '../components/emprendimiento/unities'
import Contact from '../components/emprendimiento/contact'
import Location from '../components/emprendimiento/location'
import Banner from '../components/emprendimiento/banner';
import Description from '../components/emprendimiento/description'
import BannerTOP from '../components/Modules/MediaPush/BannerTop';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Emprendimiento(){
    const dispatch = useDispatch();
    const {id} = useParams(); 
    useEffect(() => {
        const getDev = () => {
            dispatch(getDevelopmentAction(id))
            dispatch(getUnitsDevelopmentAction(id))
        }
        getDev()
    }, [])
        
    ScrollToTopOnMount()

    const { actions } = useSelector(store => store.actions)

    const checkActions = () => {
        if (actions && actions?.length > 0) {
            const devActions = actions?.filter(a => a?.developments?.length > 0);
            if (devActions?.length > 0) {
                let actualDevWithAction = [];
                devActions?.forEach(actualDev => {
                    if (actualDev?.developments) {
                        actualDev?.developments?.forEach(devId => {
                            if(devId?.toString() === id?.toString())
                                actualDevWithAction.push(actualDev)
                        })
                    }
                })
                return (actualDevWithAction?.length > 0) ? true : false
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return(
        <div className="Emprendimiento">
             <div className="layout">
                { checkActions() ? <BannerTOP page="Ficha de emprendimiento" /> : null }
                {/* <Main /> */}
                <div className="content">
                    { !(checkActions()) ? <Banner /> : null }
                    <Data />
                    <Description />
                    {/* <Projects /> */}
                    <DevImgGallery />
                    <Video />
                    <Unities />
                    <Details />
                    <Location />
                    <Contact />
                </div>
            </div>
        </div>
    )
}

export default Emprendimiento
