import React from 'react'
// SVG
import areadedropoff from '../../assets/images/customTags/areadedropoff.svg'
import bicicletero from '../../assets/images/customTags/bicicletero.svg'
import cochera from '../../assets/images/customTags/cochera.svg'
import espaciodecoworking from '../../assets/images/customTags/espaciodecoworking.svg'
import gimnasio from '../../assets/images/customTags/gimnasio.svg'
import kamados from '../../assets/images/customTags/kamados.svg'
import laundry from '../../assets/images/customTags/laundry.svg'
import parrilla from '../../assets/images/customTags/parrilla.svg'
import pileta from '../../assets/images/customTags/pileta.svg'
import saladereuniones from '../../assets/images/customTags/saladereuniones.svg'
import seguridad24hs from '../../assets/images/customTags/seguridad24hs.svg'
import solarium from '../../assets/images/customTags/solarium.svg'
import sum from '../../assets/images/customTags/sum.svg'
import tecnologiasmarthome from '../../assets/images/customTags/tecnologiasmarthome.svg'
import terraza from '../../assets/images/customTags/terraza.svg'

const svg = [
    {
        name: "areadedropoff", 
        src: areadedropoff 
    },
    {
        name: "bicicletero",
        src: bicicletero 
    },
    {
        name: "cochera",
        src: cochera 
    },
    {
        name: "espaciodecoworking",
        src: espaciodecoworking 
    },
    {
        name: "gimnasio",
        src: gimnasio 
    },
    {
        name: "kamados",
        src: kamados 
    },
    {
        name: "laundry",
        src: laundry 
    },
    {
        name: "parrilla",
        src: parrilla 
    },
    {
        name: "pileta",
        src: pileta 
    },
    {
        name: "saladereuniones",
        src: saladereuniones 
    },
    {
        name: "seguridad24hs",
        src: seguridad24hs 
    },
    {
        name: "solarium",
        src: solarium 
    },
    {
        name: "sum",
        src: sum 
    },
    {
        name: "tecnologiasmarthome",
        src: tecnologiasmarthome 
    },
    { 
        name: "terraza",
        src:  terraza
    }
];

const customTag = ({tag, alt}) => {
    
    const actualImg = svg.filter(i => i.name === tag)[0];

    return <img src={actualImg?.src} className="tag-icon" alt={alt} role="img" />
}

export default customTag