import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const getCurrentDimension = () => {
    return {
          x: window.innerWidth,
          y: window.innerHeight
    }
}

const DevImgGallery = () => {

    const {data} = useSelector(store => store.developments.development)
    const [winSize, setWinSize] = useState(getCurrentDimension())
    const [thumPos, setThumbPos] = useState("right")

    const imagesToShow = data?.photos?.map((p) => ({
        original: p.image,
        thumbnail: p.thumb
    }));

    useEffect(() => {
        if (window.innerWidth >= 992 
         && window.innerHeight >= 796) { 
            setThumbPos("right") 
        } else { 
            setThumbPos("bottom") 
        }
    }, [])

    useEffect(() => {
        const handleWinResize = () => {
            setWinSize(getCurrentDimension());
            const {x,y} = winSize;
            if (x >= 992 && y >= 796) { setThumbPos("right") }
                else { setThumbPos("bottom") }
        }
        window.addEventListener('resize', handleWinResize)
        return () => window.removeEventListener('resize',handleWinResize)
    }, [winSize])

    return data ? (
        <section id="image-gallery">
            <header className="container section-header wow fadeInLeft " style={{position: "absolute",left: "23%", top: "4%"}} >
                <strong class="fade-title-left ">Imágenes</strong>
            </header>
            <div className="container">
                <div className="section-text-header" style={{textAlign: "center", marginBottom: "1em"}}>
                    <h2>Galería de imágenes</h2>
                </div>
                { !!imagesToShow?.length > 0 && (
                        <ImageGallery 
                            items={imagesToShow} 
                            showPlayButton={false}
                            thumbnailPosition={thumPos}
                            showFullscreenButton={false} /> )}
            </div>
        </section>
    ) : null
}

export default DevImgGallery