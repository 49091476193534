import {useSelector} from 'react-redux'
import {getNoveltieAction, getNoveltiesAction} from '../../redux/noveltiesDucks'
import {useDispatch} from 'react-redux'
import { useEffect } from 'react'
import {useParams } from "react-router-dom";


const Main = () => {
  const novelty = useSelector(state => state.novelties.novelty)
  const loading = useSelector(state => state.novelties.loading)
  const dispatch = useDispatch()
  const {id} = useParams(); 

  useEffect(() => {
    // dispatch(getNoveltieAction(id));
    // dispatch(getNoveltiesAction())
  }, []);

    return !loading ? (
        <main class="main main-inner main-blog bg-blog" data-stellar-background-ratio="0.6" style={{padding: "1vmin 0 33.7vmin"}}>
        {/* <!-- Lines --> */}
        <div class="page-lines">
          <div class="container">
            <div class="col-line col-xs-4">
              <div class="line"></div>
            </div>
            <div class="col-line col-xs-4">
              <div class="line"></div>
            </div>
            <div class="col-line col-xs-4">
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </main>
    )  :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main;