import React, { useEffect, useRef }  from 'react';
// import { useLocation } from '@reach/router'
import {useLocation} from "react-router-dom";
// import Countdown from 'react-countdown';
import OwlCarousel from 'react-owl-carousel3'
import { getActionByPage, getActionByType, getCodes } from '../../../functions/functions_actions';
import { useSelector } from 'react-redux';
import { informCustomEvent } from '../../../functions/functions.analytics';
import { useParams } from "react-router-dom";

const BannerTOP = ({page}) => {

    const { actions } = useSelector(store => store.actions);
    const { data } = useSelector((store) => store.developments.development);
    const { id } = useParams();

    const location = useLocation()
    const slider = useRef()

    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),page)
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),page)
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),page)

    const getSize = () => (screen.width <= 992) ? true : false

    const goTo = () => {
        if (video_action[0].url_destiny) {
            informCustomEvent("PUSH_" + video_action[0].small_section + "_" + video_action[0].small_type + "_" + video_action[0].name)
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
        }
    }

    useEffect(() => {
        if(video_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(video_action[0]?.type,video_action[0]?.section) + "_" + video_action[0]?.name) 
        }
        if(image_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(image_action[0]?.type,image_action[0]?.section) + "_" + image_action[0]?.name) 
        }
        if(slider_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(slider_action[0]?.type,slider_action[0]?.section) + "_" + slider_action[0]?.name) 
        }
    },[])

    const dataTitle = () => {
        return (data && data?.publication_title)
                ? <h2 className="publication-title position-absolute">
                    {data?.publication_title?.split(".")[0]}</h2>
                : null
    }

    const mobileData = (files) => {
        if (getSize()) {
            const mobData = files?.filter(f => f?.device === 'mobile')[0];
            if (mobData) {
                const srcData = (mobData) ? mobData?.file : files[0]?.file[0];
                return <video loop autoPlay muted className={"video-content" + (data && data?.publication_title ? " position-absolute" : "")}>
                    <source src={srcData} type="video/mp4" />
                </video>
            } else {
                const imgSrc = data?.photos.find((element) => element?.is_front_cover == true);
                return <div className="video-banner-mobile" style={{ backgroundImage: `url("${(imgSrc ? imgSrc.original : "")}")`}}></div>

            }
        } else {
            return <video loop autoPlay muted className={"video-content" + (data && data?.publication_title ? " position-absolute" : "")}>
                <source src={files[0]?.file[0]} type="video/mp4" />
            </video>
        }
    }

    const desktopData = (files) => {
        if (!getSize()) {
            const deskData = files?.filter(f => f?.device === 'desktop')[0];
            const srcData = (deskData) ? deskData?.file : files[0]?.file[0];
            return <video loop autoPlay muted className={"video-content" + (data && data?.publication_title ? " position-absolute" : "")}>
                <source src={srcData} type="video/mp4" />
            </video>
        } else {
            return <video loop autoPlay muted className={"video-content" + (data && data?.publication_title ? " position-absolute" : "")}>
                <source src={files[0]?.file[0]} type="video/mp4" />
            </video>
        }
    }

    const actualDev = (video_action) => {
        let actualDev = [];
        video_action?.forEach(action => {
            if (action?.developments) {
                action?.developments?.forEach(devId => {
                    if (id) {
                        if(devId?.toString() === id?.toString())
                            actualDev.push(action)
                    }
                })
            }
        })
        return actualDev[0];
    }

    return (
        slider_action.length > 0 || video_action.length > 0 || image_action.length > 0
        ?   <>
                {
                    slider_action.length > 0
                    &&  <>
                            <div className={"full-slider" + (data && data?.publication_title ? " dev-gallery-container" : "")} id="section-galery-home">
                                <div id="dev-secuencial">
                                    <div className="slider" id="dev-gallery">
                                        <OwlCarousel
                                            items={1}
                                            ref={slider}
                                            margin={0}
                                            nav={false}
                                            dots={true}
                                            loop={true}
                                            rewind={true}
                                            autoplay={true}
                                            autoplayTimeout={slider_action[0]?.transition_time * 1000}
                                            id="prop-gallery">
                                                { slider_action[0].files.map((file, index)=>(
                                                    <div className={"item banner-main banner-ficha emprendimiento " + (file.url_destiny === '' ? 'cursor-default' : 'cursor-pointer')} key={index}>
                                                        <img src={file.file} className="object-cover object-center cursor-pointer"/>
                                                        <div className="info-banner position-absolute">
                                                            <div className="info row align-items-center text-center justify-content-center">
                                                                <div className="col-8 col-lg-12">
                                                                    <a onClick={() => informCustomEvent("PUSH_" + slider_action[0].small_section + "_" + slider_action[0].small_type + "_" + slider_action[0].name)} target={'_blank'} href={file.url_destiny + slider_action[0].text_utm} className={"link-gallery " + (!file.url_destiny && 'd-none')} ></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) }
                                        </OwlCarousel>
                                        <div onClick={() => slider.current.prev()} className="owl-prev"><i className="icon-arrow"> </i></div>
                                        <div onClick={() => slider.current.next()} className="owl-next"><i className="icon-arrow"> </i></div> 
                                    </div>
                                </div>
                                {/* <Searcher /> */}
                                {/* { dataTitle() } */}
                            </div>
                        </>
                }
                {
                    video_action.length > 0
                    &&  <>
                            <div className={"banner-main " + (actualDev(video_action).url_destiny === '' ? 'cursor-default' : 'cursor-pointer') + (data && data?.publication_title ? " dev-video-container" : "")}  onClick={() => goTo() }>
                                { getSize()
                                    ? mobileData(actualDev(video_action)?.files)
                                    : desktopData(actualDev(video_action)?.files) }
                                    { dataTitle() }
                                    {/* <Searcher /> */}
                            </div>
                        </>
                }
                {
                    image_action.length > 0
                    &&
                        <div className={"image-container" + (data && data?.publication_title ? " dev-image-container" : "")}>
                            <div id="home-main" onClick={() => image_action[0].url_destiny ? window.open(image_action[0].url_destiny + image_action[0].text_utm, '_blank', 'noopener,noreferrer') + informCustomEvent("PUSH_" + image_action[0].small_section + "_" + image_action[0].small_type + "_" + image_action[0].name) : ''} class={"banner-main image-push " + (image_action[0]?.url_destiny === '' ? 'cursor-default' : 'cursor-pointer') }  style={{zIndex:"9",backgroundImage:'url(' + (image_action[0]?.file + ")")}} >
                            </div>
                            {/* { dataTitle() } */}
                        </div>
                }
            </>
        : null
    )
}

export default BannerTOP

// export default connect(state => ({
//     actions:state.actions.actions,
// }),null)(BannerTOP);