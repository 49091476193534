import { data } from 'jquery'
import { useSelector } from 'react-redux';
import Map from '../map'
const Location = () => {
    const {data} = useSelector((store) => store.developments.development);

    return data ? (
        <>
            <section style={{marginTop: "5em",textAlign: "center"}} id="ubicacion">
                <div className="section-text-header position-relative" style={{position: "relative", textAlign: "center", marginBottom: "1em"}}>
                    <h2>Ubicación</h2>
                    <p className="mb-0">{data.fake_address ? (data.fake_address +  ", " + data.location.full_location.split(" | ")[1]) + "." : ''}</p>
                    <header class="container section-header wow fadeInRight " style={{position: "absolute", top: "30%", right: "15%"}}>
                        <strong class="fade-title-right">Mapa</strong>
                    </header>
                </div>
                <div className="dev-map" style={{ marginTop: "3.5em" }}>
                    <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} baseLayer={false} devData={data}/>
                </div>
            </section>
        </>
    )
    :
    ''

}

export default Location