import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentsAction,cleanDevelopmentAction,cleanUnitiesAction} from '../redux/developmentsDucks';


// -- Componentes -- //

import Main from '../components/emprendimientos/main'
import About from '../components/emprendimientos/about'
import Projects from '../components/emprendimientos/projects'
import Contact from '../components/emprendimientos/contact'
import BannerTOP from '../components/Modules/MediaPush/BannerTop';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Emprendimientos(){

    const dispatch = useDispatch();
    const filtersDevelopment = useSelector((store) => store.developments.filtersDevelopment);

    const pathname = useLocation()

    useEffect(() => {
        const getDevs = () => {
            dispatch(getDevelopmentsAction())
            dispatch(cleanDevelopmentAction())
            dispatch(cleanUnitiesAction())
        }
        getDevs()
    }, [filtersDevelopment,pathname])

    ScrollToTopOnMount()
    return(
        <div className="emprendimientos">
            <div className="layout">
                <BannerTOP page="emprendimientos" />
                <Main />
                <div className="content">
                    <About />
                    <Projects />
                    <Contact />
                </div>
            </div>
        </div>
    )
}

export default Emprendimientos
