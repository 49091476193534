export const getFeatured =  (data) => {
    return data.objects.filter(element => element.is_starred_on_web === true)
}

export const shortLocation = (full_location,position) => {
    var split = full_location.split("|")
    return split[position]
}

export const getVideo = (videos,type) => {
  if(!videos) return null
  if(videos?.length === 0) return null
  return videos?.filter(element => element?.provider?.toLowerCase() === type?.toLowerCase())[videos.length - 1]; // [videos.length - 1]
}

export const shortFull_Location = (full_location) => {
    var split = full_location.split("|");
    return split[2] + "| " + split[1];
  }

export const getPricesUnityPrice = (unity_development) => {
    var valueUnity = [];
    for (const iterator of unity_development.objects) {
      valueUnity.push(iterator.operations[0].prices[0].price);
    }
    return [Math.min.apply(null, valueUnity), Math.max.apply(null, valueUnity)];
  }

export const get_short_desc = (description,max_caracters) => {
    if (description.length > max_caracters) {
      return description.slice(0, max_caracters) + '...';
    }
    return description;
};

export const get_cover = (photos) => {
  return photos.find(element => element.is_front_cover) ? photos.find(element => element.is_front_cover) : photos[0] ? photos[0] : 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1';
}

export const get_shortDescription = (description) => {
  // if(description.length === 0){return ''}
  const spliter = description.split('.');
  var res = {
    title:'',
    description:'',
    photos:[
      '',
      '',
      '',
    ]
  }
  var offset = 0
  for (let i = 0; i < spliter.length; i++) {
      if(res.title.length <= 140)
        res.title += spliter[i] + "."
      if(res.description.length <= 200)
      {
        res.description += spliter[i] + "."
        offset++;
      }
      
    }
    for (let j = 0; j < 3; j++){
      for (let i = 0; i < spliter.length; i++) {
        if(res.photos[j].length <= 140 && i>offset){
          res.photos[j] += spliter[i] 
          offset++;
        }
      }
    }
  return res;
}

export const getGroup_CustomTag = (customTags,groupName) => {
  return customTags.find(element => element.group_name === groupName);
}

// export const group_Unities = (data) => {
//   const array = [
//   ];
//   for (let i = 0; i < data.length; i++) {
//     // Armo la nueva unidad en un objeto, separo piso de la unidad en el titulo
//     if(data[i].id === 447053){console.log(data[i])}
//     if(data[i].id !== 741886){
//       const object = {
//         //.operations[0].operation_type
//         //.id
//         //.unroofed_surface
//         //.status
//           // 1- Tasacion
//           // 2- Disponible
//           // 3- Reservado
//           // 4- No disponible
//         //.type.name
//         //.location.name
//         floor: data[i].real_address.split("-")[0],
//         unity: {
//           address:data[i].real_address.split("- ")[1],
//           id:data[i].id,
//           status:data[i].status,
//           type:data[i].type.name,
//           location:data[i].location.name,
//           surface:data[i].roofed_surface,
//           rooms:data[i].room_amount,
//           operation:data[i].operations[0].operation_type
//         }
//       }
//       // // Verifico si existe el piso o lo tengo que crear
//       const finder = array.find(element => element.floor === object.floor)
//       if(array.find(element => element.floor === object.floor)){
//         finder.unities.push(object.unity)
//       }
//       else{
//         array.push({
//           floor:object.floor,
//           unities:[object.unity]
//         })
//       }
//     }
//   }

//   let pisos = []
//   let subsuelos = []
//   let nivel = []
//   let duplex = []


//   let response= [];
  
//   for (const iterator of array) {
//     if(iterator.floor.split(" ")[0].toLowerCase().includes("piso")){
//       if(iterator.floor.includes("PB")){
//         pisos.unshift(iterator);
//       }
//       else{
//         pisos.push(iterator);
//       }

//     }
//     if(iterator.floor.split(" ")[0].toLowerCase().includes("subsuelo")){
//       subsuelos.push(iterator);
//     }
//     if(iterator.floor.split(" ")[0].toLowerCase().includes("nivel")){
//       nivel.push(iterator);
//     }
//     if(iterator.floor.split(" ")[0].toLowerCase().includes("dúplex")){
//       duplex.push(iterator);
//     }
//   }
//   pisos = pisos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
//   subsuelos = subsuelos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
//   duplex = duplex.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})

//   for (const iterator of subsuelos) {
//     response.push(iterator);
//   }
//   for (const iterator of nivel) {
//     response.push(iterator);
//   }
//   for (const iterator of pisos) {
//     response.push(iterator);
//   }
//   for (const iterator of duplex) {
//     response.push(iterator);
//   }
//   console.log(response)
//   return response;
//   // return array.sort(function(a, b){return a.floor - b.floor});
// }
export const group_Unities = (data,filter = 0, ids=[]) => {
  const array = [
  ];
  for (let i = 0; i < data.length; i++) {
    // Armo la nueva unidad en un objeto, separo piso de la unidad en el titulo
    // if(data[i].id === 447053){console.log(data[i])}
    if(filter === data[i].room_amount || filter === 0){
        let object = {}
        if(data[i].id !== 741886){
          object = {
            floor: data[i].real_address.split("-")[0],
            unity: {
              address:data[i].real_address.split("- ")[1],
              title:data[i].real_address,
              id:data[i].id,
              status:data[i].status,
              type:data[i].type.name,
              location:data[i].location.name,
              surface:data[i].roofed_surface,
              surface_total:data[i].total_surface,
              rooms:data[i].room_amount,
              operation:data[i].operations[0].operation_type,
              price: data[i].web_price ? data[i].operations[0].prices[0].price : 'Precio a consultar',
              currency:data[i].web_price ? data[i].operations[0].prices[0].currency : '',
            }
          }
        }
        

        // // Verifico si existe el piso o lo tengo que crear
        const finder = array.find(element => element.floor === object.floor)
        if(array.find(element => element.floor === object.floor)){
          if((ids.length > 0 && ids.indexOf(data[i].id.toString()) !== -1) || ids.length === 0){
            finder.unities.push(object.unity)
          }
        }
        else{
          if((ids.length > 0 && ids.indexOf(data[i].id.toString()) !== -1) || ids.length === 0){
            array.push({
              floor:object.floor,
              unities:[object.unity]
            })
          }
        }
    }
  }

  let pisos = []
  let subsuelos = []
  let nivel = []
  let duplex = []

  let response= [];
  
  for (const iterator of array) {
    if(iterator?.floor?.split(" ")[0].toLowerCase().includes("piso")){
      if(iterator.floor.includes("PB")){
        pisos.unshift(iterator);
      }
      else{
        pisos.push(iterator);
      }
    }
    if(iterator?.floor?.split(" ")[0].toLowerCase().includes("subsuelo")){
      subsuelos.push(iterator);
    }
    if(iterator?.floor?.split(" ")[0].toLowerCase().includes("nivel")){
      nivel.push(iterator);
    }
    if(iterator?.floor?.split(" ")[0].toLowerCase().includes("dúplex")){
      duplex.push(iterator);
    }
  }

  pisos = pisos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
  subsuelos = subsuelos.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})
  duplex = duplex.sort(function(a, b){return a.floor.split(" ")[1] - b.floor.split(" ")[1]})

  for (const iterator of subsuelos) {
    response.push(iterator);
  }
  for (const iterator of nivel) {
    response.push(iterator);
  }
  for (const iterator of pisos) {
    response.push(iterator);
  }
  for (const iterator of duplex) {
    response.push(iterator);
  }
  return response;
}

export const findEnvironments = (unities) => {
  const array = []
  if(unities?.length > 0){
    for(let i = 1; i < 7; i++){
    const find = unities?.find(element => element?.room_amount === i)?.room_amount
    if(find){array.push(find)}
    }
  }
  return array
}

export const findSurfaces = (unities) => {
  const array = []
  if(unities?.length > 0){
    const find = unities?.map(u => Number(u?.total_surface))?.sort((a,b) => a-b)?.filter(u => u !== 0)
    if(find){array.push(find)}
  }
  return array
}

export const findPrices = (group) => {
  const array = []
  if(group?.length > 0){
    const prices =  []
    const floors = (group?.map(g => g?.unities?.map(u => prices.push(u.price))))
    const find = (prices.filter((f => f !== 1 && f !== 'Precio a consultar')))
    if(find){
      const formatPrice = new Intl.NumberFormat("de-DE").format(find.at(0));
      array.push(formatPrice);
    }
  }
  return array
}

export const totalUnities = (group) => {
  const array = []
  if(group?.length > 0){
    const find = group?.map(g => g?.unities?.length)?.reduce((a,b) => a+b)
    if(find){array.push(find)}
  }
  return array
}

export const findConstructionDate = (unities) => {
  let dateFormat = '';
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  if(unities?.length > 0){
    const date = unities?.map(u => new Date (u?.development?.construction_date?.replaceAll('-','/')))[0];
    if(date) {
      dateFormat = `${months[date.getMonth()]} ${date.getFullYear()}`
    }
  }
  return dateFormat
}

export const getCustomTags = (property) => {
  if(property?.custom_tags) {
      return property.custom_tags
  }
  return []
}

export const getCustomTagsFiltred = (property, filter = "Mediacore") => {
  if(property?.custom_tags) {
      return property.custom_tags.filter(ct => ct.group_name.toLowerCase()
        === filter.toLowerCase())
  }
  return []
}

export const getEnvDescription = (description) => {
  const section = "Entorno y ubicación:";
  const condition = description?.search(section);
  if (condition !== -1) {
    const result = (description?.slice(condition, description?.length))?.replace(section, '');
    return result;
  } else {
    return description
  }
}

export const getShortEnvDesc = (desc) => {
  // se retorna la descripcion solamente del "Entorno y ubicación:"
  const description = getEnvDescription(desc);
  // se separa por los puntos
  const spliter = description.split('- ').slice(1,description.length);
  return spliter;

  // se agrupa por chunks
  // const chunkSize = 2;
  // const result = []
  // for (let i = 0; i < spliter.length; i += chunkSize) {
  //   result.push(spliter.slice(i, i + chunkSize))
  // }
  // return result.map(c => c.join('.').replaceAll('\n', ''));
}

export const getEnvPhotos = (photos) => {
  if (photos?.length > 0) {
    const filtered = photos?.filter(p => p.description === 'entorno')
    return (filtered?.length > 0) ? filtered : photos
  }
  return []
}

export const getPhotosNoEnv = (photos) => {
  if (photos?.length > 0) {
    const firstImg = photos[0];
    const copyPhotosArr = photos.slice(1).concat(firstImg);
    const filtered = copyPhotosArr?.filter(p => p.description !== 'entorno')
    return (filtered?.length > 0) ? filtered : photos
  }
  return []
}

export const getNoEnvDescription = (description) => {
  const section = "Entorno y ubicación:";
  const condition = description?.search(section);
  if (condition !== -1) {
    const envText = description?.slice(condition, description?.length);
    const result = description?.replace(envText, '');
    return result;
  } else {
    return description
  }
}

export const getShortNoEnvDesc = (desc) => {
  const description = getNoEnvDescription(desc);
  const spliter = description.split('.');
  const chunkSize = 2;
  const result = []
  for (let i = 0; i < spliter.length; i += chunkSize) {
    result.push(spliter.slice(i, i + chunkSize))
  }
  return result.map(c => c.join('.').replaceAll('\n', ''));
}