import { useEffect ,useState } from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom"
import {shortDirection} from '../../functions/functions_property'
import {get_shortDescription, getGroup_CustomTag,group_Unities,findEnvironments} from '../../functions/functions_dev'
import ReactGA from 'react-ga';
import useGAEventTracker from "../../functions/useGAEventsTracker";
ReactGA.initialize('UA-227716759-2');
const Data = () => {
    // Store redux 
    const settings = useSelector(store => store.settings)
    const { data } = useSelector((store) => store.developments.development);
    const unities = useSelector((store) => store.developments.units_development).data;
    const [group,setGroup] = useState()
    const [load,setLoad] = useState(false)
    const [filters,setFilters] = useState(0)
    const [buttonsFilters,setButtonsFilters] = useState([])
    const query = new URLSearchParams(location.search);
    const idUnidades = query.get('unidades')?.split("-") // Obtengo IDS por URL
    
    const GAEventsTracker = useGAEventTracker("Unidades");

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities.objects,filters,idUnidades))
            setLoad(true)
        }
    },[unities,filters])

    useEffect(() => {
        if(unities?.objects){
            setButtonsFilters(findEnvironments(unities.objects))
        }
    },[unities])

    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    console.log(buttonsFilters)


  // Render
  return unities ? (
    <section className={"container unities " + (unities.meta.total_count === 0 ? 'd-none' : '')} id="unidades">
        <div className="section-text-header position-relative" style={{textAlign: "center", marginBottom: "3em"}}>
            <h2>UNIDADES</h2>
            <p>Seleccione el tipo de unidad que está buscando</p>
            <div className="col-12">
                <div className="d-flex flex-wrap justify-content-center mt-5 filter-btn-wrapper">
                {
                    buttonsFilters.length > 1 && <div className={"tag-filter proyect-bg me-3 mb-3 " + (filters === 0 ? ' active ' : '') 
                    // + ( data ? 'bg-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                    } onClick={() => updateFilter(0)}> Todos</div>
                }
                {
                    buttonsFilters.length > 1
                    && buttonsFilters.map(element => (
                        <div className={"tag-filter proyect-bg me-3 mb-3 " + (findEnvironments(unities.objects,element) ? '' : ' d-none ' ) + (filters === element ? ' active ' : '') 
                        // + ( data ? 'bg-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                        } onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                    ))
                } 
                </div>
            </div>
            <header className="container section-header wow fadeInRight" style={{position: "absolute", top: "-30px", right: "-30px"}}>
                <strong className="fade-title-right">Unidades</strong>
            </header>
        </div>
        <div style={{marginTop: "5em"}} className="unity-group-accordion">
            {
                group && load ? 
                group.map((item,index) => (
                        <div className="unity collapsed" data-bs-toggle="collapse" data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                            {/* <div className="head-row row">
                                <div className="col-lg-6 col-4">
                                    <span className="unity-font">{item.floor}</span>
                                </div>
                                <div className="col-lg-2 col-3" style={{textAlign:'center'}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                </div>
                                <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Superficie cubierta </span>
                                </div>
                                <div className="col-1" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                    <img src={settings.base_url + "img/img-icon/icon-arrow.svg"} alt="icono de flecha" />
                                </div>
                            </div> */}
                            <div className="head-row row">
                                <div className="col-lg-3 col-3">
                                    <span className="unity-font">{item.floor}</span>
                                </div>
                                <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                </div>
                                <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
                                </div>
                                <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. cubierta </span>
                                </div>
                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                    <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
                                </div>
                                <div className="col-lg-1 col-2" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                    <img src={settings.base_url + "img/img-icon/icon-arrow.svg"} alt="icono de flecha" />
                                </div>
                            </div>
                            <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                            {/* .split(" ")[1] */}
                                    {/* {item.unities.sort(function(a, b){return a.address.localeCompare(b.address)}).map((element,index) => ( */}
                                    {item.unities.sort(function(a, b){return a.address.split(" ")[1] - b.address.split(" ")[1]}).map((element,index) => (
                                        (element.status === 2 ?
                                            <>
                                                <Link onClick={(e) => GAEventsTracker("Ingreso ficha",element.title + " - " + data.name + " - " + element.id)} to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
                                                    <div className={"unity-row row " + (element.status !== 2 ? 'disabled' : '')} >
                                                        <div className="col-lg-3 col-3">
                                                            <span className={"unity-font " 
                                                            // + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                            }>{element.address}</span>
                                                        </div>
                                                        <div className="col-lg-2 col-3 d-none d-lg-block"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font " 
                                                            // + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                            } style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-3"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font " 
                                                            // + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                            } style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m2<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-4 d-none d-lg-block"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font " 
                                                            // + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                            } style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m2<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-4"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font " 
                                                            // + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                            } style={{minWidth:'5rem',textAlign:"center"}}>{element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price}<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-1 col-2" style={{textAlign:"center"}}>
                                                            {/* <span className={"unity-font " + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')} style={{minWidth:'5rem',textAlign:"center"}}>{element.surface}m<sup>2</sup></span> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </>
                                        :
                                            <>
                                                <div to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
                                                    <div className={"unity-row row disabled"} >
                                                        <div className="col-lg-3 col-3">
                                                            <span className={"unity-font " 
                                                            //  + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                             }>{element.address}</span>
                                                        </div>
                                                        <div className="col-lg-2 col-3 d-none d-lg-block"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font "
                                                            //  + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                             } style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-3"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font "
                                                            //  + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                             } style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m2<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-4 d-none d-lg-block"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font "
                                                            //  + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                             } style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m2<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-2 col-4"  style={{textAlign:'center'}}>
                                                            <span className={"unity-font "
                                                            //  + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')
                                                             } style={{minWidth:'5rem',textAlign:"center"}}>{element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price}<sup></sup></span>
                                                        </div>
                                                        <div className="col-lg-1 col-2" style={{textAlign:"center"}}>
                                                            {/* <span className={"unity-font " + ( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')} style={{minWidth:'5rem',textAlign:"center"}}>{element.surface}m<sup>2</sup></span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    ))}
                            </div>
                        </div>
                    // console.log(item.floor)
                    ))
                : //Están cargando los desarrollos
                <>
                    <div class="loader relative">
                        <div class="page-lines">
                            <div class="container">
                            <div class="col-line col-xs-4">
                                <div class="line"></div>
                            </div>
                            <div class="col-line col-xs-4">
                                <div class="line"></div>
                            </div>
                            <div class="col-line col-xs-4">
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                            </div>
                        </div>
                        <div class="loader-brand">
                            <div class="sk-folding-cube">
                            <div class="sk-cube1 sk-cube"></div>
                            <div class="sk-cube2 sk-cube"></div>
                            <div class="sk-cube4 sk-cube"></div>
                            <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
        <p className="text-center" style={{color: "#525252" , marginTop: "3em"}}>* Las unidades inactivas  no están disponibles.</p>
    </section>
  ):''
};

export default Data;
