import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getActionByPage, getActionByType } from "../../functions/functions_actions";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";


const Main = () => {

    const dispatch = useDispatch();

    const settings = useSelector((store) => store.settings);
    const [listActives,setListActive] = useState([])
    const {data} = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    const actions = useSelector(store => store.actions.actions)


    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

  // Update filters in redux
  useEffect(() => {
    // if(activeTags.length > 0){
      dispatch(updateFiltersNovelties(listActives));
    // }
  }, [listActives])

  useEffect(() => {
    dispatch(clearNoveltyAction());
  } ,[])

    return tags ? (
        <main class="main main-inner main-blog bg-blog bg-zoom-in-out" data-stellar-background-ratio="0.6">
        <div class="container">
            <header class="main-header">
                <h1>Novedades <span class="text-primary ">RED</span></h1>
            </header>
            <div className="row mobile-block">
                <div className="selected">
                    <div>
                        <i className="icon-tag"></i>
                        {listActives.map((element, index) =>(
                            <div className="tag active wow fadeInRight"  style={{marginRight:"10px"}}>
                                {tags.find(item => item.id === element).name}
                                <button onClick={() => toggleTag(element)}><i className="icon-delete"></i></button>
                            </div>
                        ))}
                    </div>
                    <span onClick={() => setListActive([])}>Limpiar</span>
                </div>
                <button onClick={() => dispatch(getFiltersNoveltiesAction(listActives))} className="btn wow swing"> Buscar <i className="icon-search"></i></button>
            </div>

            <div className="row mt-4">
                {tags.map((element,index) => (
                    listActives.find(e => e === element.id) ? '' :
                    <div className="col-xs-4 pl-lg-0 ">
                        <div key={index} onClick={() => toggleTag(element.id)} className="tag wow fadeInRight">{element.name}</div>
                    </div>
                ))}
            </div>
        </div>

        {/* <!-- Lines --> */}

        <div class="page-lines">
            <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </main>
    ):''
}

export default Main