import React , {useEffect} from 'react'

// -- Componentes -- //

import Main from '../components/nosotros/main'
import About from '../components/nosotros/about'
import Experiencie from '../components/nosotros/experience'
import Team from '../components/nosotros/team'
import Contact from '../components/form'
import BannerTOP from '../components/Modules/MediaPush/BannerTop'


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Nosotros(){

    ScrollToTopOnMount()
    return(
        <div className="nosotros">
            <div className="layout">
                <BannerTOP page="nosotros" />
                <Main />
                <div className="content">
                <About />
                <Experiencie />
                <Team />
                <Contact />
                </div>
            </div>
        </div>
    )
}

export default Nosotros
