import React, { useState, useEffect } from 'react'
import L from 'leaflet'
import IconGrey from "../assets/images/map-icon-grey.png"
import IconRed from "../assets/images/map-icon-red.png"
import "../../node_modules/leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Popup, ZoomControl, Marker } from "react-leaflet"

const getMap = (props) => {

  const { coordenadas, baseLayer = true, devData } = props;
  const position = [coordenadas?.geo_lat, coordenadas?.geo_long];
  const zoom = 16;

  const [tileStyle, setTileStyle] = useState("https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png");
  const baseTileLayer = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";
  const tileLayerDev = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',.png";
  
  useEffect(() => {
    setTileStyle(lastVal => baseLayer ? baseTileLayer : tileLayerDev)
    setIconStyle(lastVal => baseLayer ? IconRed : IconGrey)
  },[baseLayer])
  
  const [iconStyle, setIconStyle] = useState(IconRed);
  const myIcon = L.icon({
    iconUrl: iconStyle,
    iconSize: [27, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],
    shadowSize: [41, 41],
    shadowAnchor: [12.5, 41]
  })

  return coordenadas ? (
    <div id="map" style={{ height: "100%" }}>
      <MapContainer dragging={!L.Browser.mobile} tap={false} touchZoom={true} center={position} zoom={zoom} scrollWheelZoom={false} zoomControl={false} style={{ height: "100%" }}>
      <TileLayer url={tileStyle} maxZoom={zoom}/>
      <ZoomControl position="bottomright" />
      <Marker icon={myIcon} position={position}>
        { devData ? <Popup>{devData?.address + ", " + devData?.location?.name}</Popup> : null }
      </Marker>
    </MapContainer>
    </div>
  ) : null

}

export default getMap