import * as React from "react"
import { useSelector } from "react-redux"
import AlertTop from './Modules/MediaPush/AlertTop'
import FlapButton from './Modules/MediaPush/FlapButton'
import CounterPopup from "./Modules/MediaPush/CounterPopup"
import SquarePop from './Modules/MediaPush/SquarePop'
import { getActionByType } from '../functions/functions_actions'
import { useLocation } from "react-router-dom"
// import "../assets/styles/global/modules/media-push/bootstrap.min.css"


const Modules = () => {

  const { pathname } = useLocation()
  const actions = useSelector(store => store.actions.actions)
  const development = useSelector((store) => store.developments.development).data;
  const property = useSelector((store) => store.properties.property).data;
  const isViewDevelopment = pathname?.split("/")[1]?.includes("desarrollo");
  const isViewUnity = pathname?.split("/")[1]?.includes("unidad");

  const alertObj = {
    section: "nosotros",
    type: "DESTAQUE TOP",
    url_destiny: "destino",
    title: "titulo",
    text: {
        text: "texto",
        text_band: "banda",
        text_button: "boton"
    },
    date_start: "2022-10-10T17:38:36.524669Z",
    date_end: "2022-10-11T00:00:00.000000Z",
    eventDate: `${new Date().getMonth() + 2}/${new Date().getDay() + 2}/${new Date().getFullYear()}`
  }

  return (
    <>
      {getActionByType(actions,'Solapa Flotante').map((action => (
        <FlapButton 
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_limited}
          alignment={action.alignment}
          utmValue={action.texts.text_utm}
          showOn={action.section}/>
      )))}
      {getActionByType(actions,'PopUp Timer').map((action => (
        <CounterPopup 
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments} 
          linkTo={action.texts.text_URL} 
          linkTxt={action.texts.text_button} 
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          title={action.title}
          showOn={action.section}
          band={action.texts.text_band} 
          text={action.texts.text}
          utmValue={action.texts.text_utm}
          eventDate={action.date_start}/> 
      )))}
      {getActionByType(actions,'PopUp Vertical').map((action => (
        <SquarePop 
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          align={action.alignment} 
          flat={false} 
          linkTo={action.url_destiny}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          image={action.file}
          utmValue={action.text_utm}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'PopUp Cuadrado').map((action => (
        <SquarePop 
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          align={""} 
          flat={false} 
          linkTo={action.url_destiny}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          image={action.file}
          utmValue={action.text_utm}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'PopUp Panoramico').map((action => (
        <SquarePop 
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          align={""} 
          flat={true} 
          linkTo={action.url_destiny}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          utmValue={action.text_utm}
          image={action.file}
          showOn={action.section} /> 
      )))}
      {getActionByType(actions,'Barra Encabezado Timer').map((action => (
        <AlertTop  
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_button}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          utmValue={action.texts.text_utm}
          textAlert={action.texts?.text}
          showOn={action.section}
          eventDate={action.date_start}/>
      )))}
      {getActionByType(actions,'Barra Encabezado').map((action => (
        <AlertTop  
          actions={actions}
          property={isViewUnity ? property : undefined}
          development={isViewDevelopment ? development : undefined}
          properties={action.properties}
          developments={action.developments}
          linkTo={action.texts.text_URL}
          linkTxt={action.texts.text_button}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          utmValue={action.texts.text_utm}
          textAlert={action.texts?.text}
          showOn={action.section}/>
      )))}
    </>

  )
}

export default Modules;
