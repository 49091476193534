import Aos from "aos";
import 'aos/dist/aos.css';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getEnvDescription, getShortEnvDesc, getEnvPhotos } from "../../functions/functions_dev";

const Data = () => {

  const { data } = useSelector((store) => store.developments.development);

  const formatText = (t, cond = false) => {
    const text = new String(t);
    const lastChar = text.trim().replace('\n','').substring(text?.length - 1);
    return (cond)
      ? ((lastChar.match(/[A-Za-z]/g)) 
      || (lastChar !== '.') ? "." : "")
      : ''
  }

  useEffect(() => {
    Aos.init({ duration : 2000})
  }, [])

  // Render
  return data ? (
    <>
    { (getEnvDescription(data?.description)?.length > 0 && getEnvPhotos(data.photos)?.length > 0) ?
      <section id="development-location" class="project-details" style={{position: "relative"}}>              
        <header class="container section-header wow fadeInLeft " style={{position: "absolute",left: "20%",top: "150px"}} >
            <strong class="fade-title-left ">Entorno</strong>
        </header>
        <div style={{maxWidth: "600px",margin: "auto",marginTop: "5em",marginBottom: "5.5em"}} className="title-section">
            <h2 style={{textAlign: "center", marginBottom: "1em"}}>Entorno y ubicación</h2>
        </div>
        <div class="container">
        { getShortEnvDesc(data.description).map((d,i) => (
         <>{getEnvPhotos(data.photos)[i] && d?.length > 0 ?
              <div className="project-details-item container">
                <div className="row">
                <div className={"project-details-info wow " + ((i%2 === 0) ? "fadeInLeft bg-yellow-theme" : "fadeInRight")} data-aos={(i%2 === 0) ? "fade-right" : "fade-left"} data-aos-offset="200" data-aos-easing="ease-in-sine">
                    <p className="project-details-descr">{d.trim() + formatText(d)}</p>
                </div>
                <div className={"project-details-img " + ((i%2 === 0) ? "col-md-8 col-md-offset-4" : "col-md-8")}>
                    <img alt="" className="img-responsive" src={getEnvPhotos(data.photos)[i] ? getEnvPhotos(data.photos)[i].original : ''} />
                </div>
                </div>
              </div> : null}
            </>))}
        </div>
      </section>
      : null }
    </>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;


