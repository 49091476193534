import React, { useEffect, useRef, useState } from "react"
import {useLocation} from "react-router-dom";
// import { useLocation } from "@reach/router"
import Countdown from "react-countdown"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import Star from "../../../assets/images/star-popup.svg"
import Square from "../../../assets/images/cuadro.svg"
import { convertStringToTime, getCodes, getCodeFromType, getActionBySmallType } from '../../../functions/functions_actions';
import { informCustomEvent } from "../../../functions/functions.analytics";


const CounterPopup = ({linkTo, linkTxt, title, text, showOn, band, utmValue, eventDate, small_type, small_section, name, actions, properties, property, developments, development}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    // const [isOpen, setIsOpen] = useState(false)

    // Timeout to show the component
    useEffect(() => {
        // if(checkPage() && !isOpen) {
        if(checkPage()) {
            if((showOn === "Ficha de emprendimiento" && development?.id) || (showOn === "Ficha de la propiedad" && property?.id) || (showOn !== "Ficha de emprendimiento" && showOn !== "Ficha de la propiedad")){
                informCustomEvent("SHOW_" + getCodes(small_type, showOn) + "_" + name)
                setTimeout(() => {
                    pop?.current?.open()
                    // setIsOpen(true)
                }, 1000)
            }
        }
    }, [pathname,property,development])

    // Check where shows
    const checkPage = () => {
        if(developments.length > 0  || properties.length > 0 ){
            if(existTimerPopDetail(property?.id) || existTimerPopDetail(development?.id)){
                if(properties.find(prop => prop?.toString() === property?.id?.toString()) 
                || developments.find(dev => dev?.toString() === development?.id?.toString())){
                        return true
                    }
            }
            return false
        }
        else {
            if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
            if(pathname === 'red-i' && showOn.toLowerCase() === 'tasaciones'){return true}
            if(pathname.toLowerCase().includes('desarrollo') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento' && !existTimerPopDetail(development?.id)){return true}
            if(pathname.toLowerCase().includes('unidad') && showOn.toLowerCase() === 'ficha de la propiedad' && !existTimerPopDetail(property?.id)){return true}
            if(pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta'){return true}
            if(pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler'){return true}
            if(pathname.toLowerCase() === 'alquiler-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){return true}
            if(pathname.toLowerCase() === 'desarrollos' && showOn.toLowerCase() === 'emprendimientos'){console.log("true"); return true}
            return (Array.isArray(showOn)) 
                ?  showOn.toLowerCase().includes(pathname.toLowerCase())
                : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
        }
    }

    const existTimerPopDetail = (id) => {
        const TimerPop = [
            ...getActionBySmallType(actions, getCodeFromType('PopUp Vertical')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Cuadrado')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Timer')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Panoramico'))
        ] // code: PUV, PUC, PUT, PUP
        if(id){
            for (const singleSquare of TimerPop) {
                for (const dev of singleSquare.developments) {
                    if(dev.toString() === id.toString()){
                        return true
                    }
                }
                for (const prop of singleSquare.properties) {
                    if(prop.toString() === id.toString()){
                        return true
                    }
                }
            }
            return false
        }
        else{
            return false
        }
    }

    // Render countdown
    const rendererCtd = ({ days, hours, minutes, seconds }) =>
        <div className="counter d-flex justify-content-center text-center mt-4">
            <div className="mx-2 d-flex flex-column justify-content-center">
                <span className="date-val">{days}</span> 
                <span className="date-text">DÍAS</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{hours}</span> 
                <span className="date-text">HS</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{minutes}</span> 
                <span className="date-text">MIN</span>
            </div>
            <div className="me-2 d-flex flex-column justify-content-center">
                <span className="date-val">{seconds}</span> 
                <span className="date-text">SEG</span>
            </div>
        </div>

    const pop = useRef()

    return checkPage() ? (
        <Popup ref={pop} position="right center">
            <i className="icon-delete close" style={{transform: 'rotate(90deg)'}} type="button" role="button" id="closePopup"data-bs-dismiss="modal" aria-label="Close" onClick={() => pop.current.close()}></i>
            {/* <button className="close" > */}
                {/* &times; */}
            {/* </button> */}
            <div className={`content-border ${band ? 'show-band' : 'hide-band'}`}>
                {/* <Square className={`square-content ${band ? 'show' : 'hide'}`}/> */}
                <img style={{position:"absolute",top:"0"}} className={`square-content ${band ? 'show' : 'hide'}`} src={Square} alt="" />
                { band && 
                    <div className="band">
                        <span className="text-band text-center">{band}</span>
                    </div> }
                {/* <Star className="star-icon"/> */}
                <div className="star-icon">
                    {/* <i className="icon-star"></i> */}
                    <img src={Star} alt="" />

                </div>
                <h1 className="text-center text-uppercase">{title}</h1>
                {text && <p className="popup-text my-3 text-center">{text}</p>}
                <Countdown  
                    date={new Date(eventDate).getTime()} 
                    renderer={rendererCtd}/> 
                {linkTo && <a style={{display:"flex",alignItems:"center",justifyContent:"center"}} className="d-flex text-center align-items-center justify-content-center text-uppercase" 
                    onClick={() => pop.current.close() + informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name) } href={linkTo + utmValue} target="_blank">{linkTxt}</a>}
            </div>
        </Popup>
    ) : null
}

export default CounterPopup

// Component usage
// <CounterPopup 
//      linkTo={'/servicios'} -> url link
//      linkTxt={'A un click de conocerlo'} -> text link
//      title={'¡Nuevo emprendimiento!'} -> text title
//      showOn={'nosotros'} -> where show | add to components page
//      band={'Proyecto de pozo, texto de prueba'} -> band top right
//      eventDate={'9/15/2022'}/> -> event date
