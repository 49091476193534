import { Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { updateFields,formTokko } from '../redux/contactDucks'
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useHistory } from "react-router-dom";
import { getWhatsapp } from "../functions/functions_contact";

function Footer() {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  
  const [disabled, setDisabled] = useState(true);
  const [text,setText] = useState('')
  const [coordenadas, setCoordenadas] = useState(5000);
  const settings = useSelector((store) => store.settings);
  const { data } = useSelector((store) => store.developments.development);
  const theme = useSelector((store) => store.theme);
  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const currentYear = new Date().getFullYear();
  const view = curLocation ? curLocation.split("/")[0] : ''


  const [textWpp,setTextWpp] = useState("")
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
      setCoordenadas($("footer").offset().top);
      // console.log(window.pageYOffset, coordenadas)
    };
  }, [setOffset]);

  function scrollTop() {
    $("html, body").scrollTop(0);
    return true;
  }


  const [datos, setDatos] = useState({
      email:'',
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  const sendForm = (event) => {
    event.preventDefault()
    dispatch(formTokko('Newsletter'))
    document.getElementById("formContact").reset()
    setText("¡Newsletter enviado correctamente!")
}
  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  useEffect(() => {
    if(data){
      if(curLocation.split("/")[0] !== "desarrollo"){
        setTextWpp("")
      }
      else{
        setTextWpp("Quería consultar sobre el emprendimiento " + data.name)
      }
    }
  },[curLocation,data])

  // get current year function
  const getYear = () => {
    let date = new Date();
    return date.getFullYear();
  };

  return (
    <footer id="footer " class={"footer " + (view === "desarrollo" ? "dev-footer" : "")}>
       {/* + (offset <= (coordenadas - 1500) ? '' : 'opacity') */}
        {/* <div class={"fixed-bar position-fixed "}> */}
          {/* <a href={'https://web.whatsapp.com/send?text=¡Hola!&amp;phone=+5493644571181'} target="_blank" class="icon-whatsapp contact-btn d-none d-lg-flex"></a> */}
          {/* <a
            href={getWhatsapp(settings.contact.whatsapp.link,textWpp)}
            target="_blank"
            class="icon-whatsapp contact-btn d-flex"
          ></a> */}
        {/* </div> */}
      <div class="container ">
          <div class="row-base row ">
              <div class="col-base text-left-md col-md-4 ">
                  RED<span class="text-primary ">-</span>Real Estate Developers
              </div>
              <div class="text-center-md col-base col-md-4 ">
              </div>
              <div class="text-right-md col-base col-md-4 mt-0">
                <div className="copy mt-auto d-flex">
                  <p> Design by {" "}<a className="mediahaus-link"  target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus{" "} </a>{" "} - Powered By <a className="mediacore-link" target="_blank"  href="https://mediacore.app/">MediaCore{" "}</a>
                    < br /> 
                    <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span>
                  </p>
                </div>
              </div>
          </div>
      </div>
    </footer>
  );
}

// "/clientes/" + settings.short_name

export default Footer;
