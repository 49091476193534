import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
// import { EditorState, ContentState, convertToRaw } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import DateTimeInput from '../../../Globals/DateTimeInput'
import parse from "html-react-parser";
import { getDateTime } from "../../functions/functions_novelties";
import { getFeatured } from "../../functions/functions_dev";

import { useDispatch, useSelector } from "react-redux";

import {
  getNoveltieAction,
  getNoveltiesAction,
  clearNoveltyAction,
} from "../../redux/noveltiesDucks";

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const Details = () => {
  const settings = useSelector((store) => store.settings);
  const novelty = useSelector((state) => state.novelties.novelty);
  const data = useSelector((state) => state.novelties.novelties);
  const loading = useSelector((state) => state.novelties.loading);

  const imagesToShow = novelty?.images_data?.map((image) => ({
    original: image.file,
    thumbnail: image.file,
    description: image.text_alt,
    text_alt: image.text_alt,
  }))

  const [content, setContent] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (novelty) {
      if (novelty.content) {
      }
    }
  }, [novelty]);

  const getThree = (data) => {
    // Agarro los primeros 3 activos por position
    return data
      .filter(
        (item, index) =>
          item.active.toString() === "true" || item.active.toString() === "1"
      )
      .slice(0, 3);
  };

  const getFeatured = (novelties) => {
    if (novelties) {
      return novelties.filter(
        (item) =>
          item.featured === "true" ||
          item.featured === "1" ||
          item.featured === true
      );
    }
  };

  return !loading && novelty ? (
    <section class="blog-details">
      <div class="container">
        <div class="row">
          <div class="col-primary col-md-8">
            <article class="post">
              <header class="post-header">
                <div className="flex">
                  <h3>{novelty.title ? novelty.title : "Cargando.."}</h3>
                </div>
                <div class="blog-meta">
                  {/* <div class="author">
                    publicado por{" "}
                    <a>
                      {novelty.creator
                        ? (novelty.creator.first_name
                            ? novelty.creator.first_name +
                              " " +
                              novelty.creator.last_name
                            : "Anónimo") + " "
                        : "Cargando.."}
                    </a>
                  </div> */}
                  { (novelty?.author_choices && (novelty?.author_choices !== 'unspecified'))
                        ? <div class={'author ' + novelty?.author_choices}>
                            Publicado por {novelty?.author}</div>
                        : null }
                  <div class="time">
                    {novelty?.date_published
                      ? getDateTime(novelty.date_published, true)
                      : "Cargando.."}
                  </div>
                </div>
              </header>
              <div class="post-thumbnail">
                <img alt="" class="img-responsive" src={novelty.image} />
              </div>
              <div
                class="blog-meta legend-footer d-flex w-100 justify-content-end"
                style={{ justifyContent: "flex-end" }}
              >
                {novelty?.footer ? novelty?.footer : ""}
              </div>
              <div className="div_body_novelty">
                {novelty.content ? <div> {parse(novelty.content)}</div> : ""}
              </div>
              <div className={"div_gallery" + (!!imagesToShow?.length ? " gallery-space-top" : "")}>
                { !!imagesToShow?.length && (
                    <ImageGallery  showIndex showBullets
                      items={imagesToShow} showPlayButton={false} /> )}
              </div>
              <div className={"div_files" + (!!novelty?.files_data && novelty?.files_data?.length > 0 ? " files-space-top" : "")}>
                { !!novelty?.files_data && novelty?.files_data?.length > 0 ? (
                    <div className="div_header_novelty mt-5">
                      <h4 className="subtitle">Archivos para descargar</h4>
                      <div className="with-bt" />
                      { novelty?.files_data?.map((files) => (
                        <div key={files.id} className="linkStyled">
                          <a href={files.file} className="linkStyled">
                            <i className="icon-download"></i>
                            {files.file.substring(files.file.indexOf('news/'), files.file.length).substring(5)}
                          </a>
                        </div> ))}
                    </div> ) : null }
              </div>
              <div class={"blog-tags " + (novelty?.tags?.length > 0 ? " blog-space-top" : "")}>
                { novelty ? novelty.tags && novelty.tags.map((tag, index) => (
                      <span className="tag" key={index}>
                        {tag.name}
                      </span>)) : "Cargando.." }
              </div>
            </article>
          </div>
          <div class="col-secondary col-md-4">
            <div className="goBack">
              <Link className="btn" to={settings.base_url + "novedades"}>
                Volver a novedades
              </Link>
            </div>
            <div
              class="widget widget_recent_post"
              style={{ marginTop: "3rem" }}
            >
              <h3 class="widget-title">Novedades destacadas</h3>
              {data ? (
                getFeatured(data).length > 0 ? (
                  getFeatured(data).map((novelty, index) => (
                    <article class="recent-post">
                      <div class="recent-post-thumbnail">
                        <Link
                          onClick={() =>
                            dispatch(clearNoveltyAction()) +
                            dispatch(getNoveltieAction(novelty.id))
                          }
                          to={"/novedades/" + novelty.id + "/" + novelty.slug}
                        >
                          <img
                            alt=""
                            src={novelty.image}
                            class="wp-post-image"
                          />
                        </Link>
                      </div>
                      <div class="recent-post-body">
                        <h4 class="recent-post-title">
                          <Link
                            onClick={() =>
                              dispatch(clearNoveltyAction()) +
                              dispatch(getNoveltieAction(novelty.id))
                            }
                            to={"/novedades/" + novelty.id + "/" + novelty.slug}
                          >
                            {novelty.title}
                          </Link>
                        </h4>
                        <div class="recent-post-time">
                          {getDateTime(novelty.date_published)}
                        </div>
                      </div>
                    </article>
                  ))
                ) : (
                  <span style={{ textAlign: "center", display: "block" }}>
                    {" "}
                    No hay novedades destacadas.
                  </span>
                )
              ) : (
                ""
              )}
            </div>
            <div class="widget">
              {/* <div class="blog-tags">
                  <span className="tag">diseño</span>
                  <span className="tag">construcción</span>
                  <span className="tag">inversiones</span>
                  <span className="tag">arquitectura</span>
                  <span className="tag">sustentabilidad</span>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <>
      <div class="loader">
        <div class="page-lines">
          <div class="container">
            <div class="col-line col-xs-4">
              <div class="line"></div>
            </div>
            <div class="col-line col-xs-4">
              <div class="line"></div>
            </div>
            <div class="col-line col-xs-4">
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="loader-brand">
          <div class="sk-folding-cube">
            <div class="sk-cube1 sk-cube"></div>
            <div class="sk-cube2 sk-cube"></div>
            <div class="sk-cube4 sk-cube"></div>
            <div class="sk-cube3 sk-cube"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
