import {useState} from 'react'
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux'
import {shortLocation, getFeatured} from '../../functions/functions_dev'
import { useEffect } from 'react';
import { getActionByPage, getActionByType } from '../../functions/functions_actions';

const Main = () => {
    const [active,setActive] = useState(0)
    const {data} = useSelector(store => store.developments.developments)
    const [featured,setFeatured] = useState() 
    const settings = useSelector(store => store.settings)
    const actions = useSelector(store => store.actions.actions)


    const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')
 

    return (
        <main class="main main-inner bg-redi bg-zoom-in-out" data-stellar-background-ratio="0.6">
            <div class="container">
              <header class="main-header">
                {/* <img src="img/redi_respira.svg" alt="red-i" style={{maxWidth:"25em"}}/> */}
                <div class="entry">
                  <h3 class="entry-title" >
                      Solo después de estar 30 años en el mercado se alcanza lo que todos persiguen: ofrecer una alta rentabilidad, con un bajo riesgo.
                  </h3>
                  <p class="entry-text" > 
                      Red-i Respira es un programa innovador de inversión financiera con una rentabilidad superior a la del negocio inmobiliario tradicional. Con un ticket de entrada por debajo del promedio, uno puede participar del negocio, pero sin tener que convertirse en propietario. Tiene como objetivo darle una alternativa muy rentable y a la vez segura, a todos aquellos que buscan diversificar su portfolio de inversiones incorporando real estate.
                  </p>
                  <a style={{display:"flex",alignItems:"center", width:"fit-content", }} className='mt-3 btn' href="https://redsa.com.ar/red-i-respira" target="_blank" >CONOCÉ MÁS</a>
                </div>
              </header>
              {/* <header class="section-header wow fadeInRight ">
                <strong class="fade-title-right ">Trayectoria</strong>
              </header> */}
            </div>
    
          {/* <!-- Lines --> */}
    
          <div class="page-lines">
            <div class="container">
              <div class="col-line col-xs-4">
                <div class="line"></div>
              </div>
              <div class="col-line col-xs-4">
                <div class="line"></div>
              </div>
              <div class="col-line col-xs-4">
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
        </main>
    )
}

export default Main