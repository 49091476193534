import React, { useState, useEffect } from "react"
import {useLocation} from "react-router-dom";
import { informCustomEvent } from "../../../functions/functions.analytics";
import { getCodes, getCodeFromType, getActionBySmallType } from "../../../functions/functions_actions";
// import { useLocation } from "@reach/router"

const SquarePopup = ({align, flat, linkTo, showOn, image, utmValue, small_type, small_section, name, actions, properties, property, developments, development}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    // const [isOpen, setIsOpen] = useState(false)

    // Timeout to show the component
    useEffect(() => {
        // if(checkPage() && !isOpen) {
        if(checkPage()) {
            if((showOn === "Ficha de emprendimiento" && development?.id) || (showOn === "Ficha de la propiedad" && property?.id) || (showOn !== "Ficha de emprendimiento" && showOn !== "Ficha de la propiedad")){
                informCustomEvent("SHOW_" + getCodes(small_type, showOn) + "_" + name)
                setTimeout(() => {
                    if((flat && screen.width > 992) || (!flat)) {
                        document.getElementById("squarePopUpBtn")?.click()
                        // setIsOpen(true)
                    }
                }, 1000)
            }
        }
    }, [pathname,property,development])

    // Check align
    const checkAlign = () => {
        return (align.toLowerCase() === 'left') ? 'align-left' 
             : ((align.toLowerCase() === 'right') ? 'align-right' 
                : ((align.toLowerCase() === 'center') ? 'align-center' : ''))
    }

    // Limit the height
    const checkFlat = () => {
        return (flat) ? 'flat-width d-none d-lg-flex' : ''
    }

    // Go to link, disable popup
    const goTo = () => {
        // setIsOpen(false)
        document.getElementById("closePopup").click()
        if (linkTo) {
            window.open(linkTo + utmValue, '_blank', 'noopener,noreferrer')
        }
    }

    // Check where shows
    const checkPage = () => {
        if(developments.length > 0  || properties.length > 0 ){
            if(existSquarePopDetail(property?.id) || existSquarePopDetail(development?.id)){
                if(properties.find(prop => prop?.toString() === property?.id?.toString()) 
                || developments.find(dev => dev?.toString() === development?.id?.toString())){
                        return true
                    }
            }
            return false
        }
        else {
            if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
            if(pathname === 'red-i' && showOn.toLowerCase() === 'tasaciones'){return true}
            if(pathname.toLowerCase().includes('desarrollo') && pathname.length > 18 && showOn.toLowerCase() === 'ficha de emprendimiento' && !existSquarePopDetail(development?.id)){return true}
            if(pathname.toLowerCase().includes('unidad') && showOn.toLowerCase() === 'ficha de la propiedad' && !existSquarePopDetail(property?.id)){return true}
            if(pathname.toLowerCase() === 'venta' && showOn.toLowerCase() === 'resultados venta'){return true}
            if(pathname.toLowerCase() === 'alquiler' && showOn.toLowerCase() === 'resultados alquiler'){return true}
            if(pathname.toLowerCase() === 'alquiler-temporario' && showOn.toLowerCase() === 'resultados alquiler temporario'){return true}
            if(pathname.toLowerCase() === 'desarrollos' && showOn.toLowerCase() === 'emprendimientos'){return true}
            return (Array.isArray(showOn)) 
                ?  showOn.toLowerCase().includes(pathname.toLowerCase())
                : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
        }
    }

    const existSquarePopDetail = (id) => {
        const squarePopUps = [
            ...getActionBySmallType(actions, getCodeFromType('PopUp Vertical')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Cuadrado')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Timer')),
            ...getActionBySmallType(actions, getCodeFromType('PopUp Panoramico'))
        ] // code: PUV, PUC, PUT, PUP
        if(id){
            for (const singleSquare of squarePopUps) {
                for (const dev of singleSquare.developments) {
                    if(dev.toString() === id.toString()){
                        return true
                    }
                }
                for (const prop of singleSquare.properties) {
                    if(prop.toString() === id.toString()){
                        return true
                    }
                }
            }
            return false
        }
        else{
            return false
        }
    }

    return checkPage() ? (
        <>
            <div className="article">
                <button type="button" id="squarePopUpBtn" data-bs-toggle="modal" data-bs-target="#squarePopUp"></button>
                <div className="modal fade"  id="squarePopUp" tabIndex="-1" aria-hidden="true">
                    <div className={`modal-dialog ${checkAlign()} ${checkFlat()}`}>
                        <div className={`modal-content ${checkFlat()}`} style={{backgroundImage: 'url('+ image + ')'}}>
                            {/* add onClick -> setIsOpen(false) */}
                            <div className="modal-header" onClick={() => document.getElementById("closePopup")?.click()}>
                                <i style={{transform: 'rotate(90deg)'}} className="icon-delete" type="button" role="button" id="closePopup"
                                    data-bs-dismiss="modal" aria-label="Close"
                                        onClick={() => document.getElementById("closePopup")?.click() }></i>
                            </div>
                            <div className={`modal-body ${checkFlat()}`} onClick={() => informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name) + goTo() }></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : null
}

export default SquarePopup

// Component usage
// <SquarePopup -> Square aligned center by default
//      align={'right' || 'left' || 'center'} -> Set the align for vertical banner
//      flat={true} -> Square flat with max-height
//      linkTo={'/emprendimientos/'} -> Set the link of image
//      showOn={'servicios' | ['venta','alquiler'] } /> -> Set route where shows the banner
