import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom";

function About() {
  const dispatch = useDispatch();
  const settings = useSelector(store => store.settings)

  return (
    <>
    <section className="experience section " style={{marginTop: "10.1em"}}>
        <div className="container ">
            <div className="text-parallax " data-stellar-background-ratio="0.85 " style={{backgroundImage: "url( 'img/bg/text-1.jpg')"}}>
                <div className="text-parallax-content ">30</div>
            </div>
            <h4 className="experience-info wow fadeInRight "><span className="text-primary ">años </span><br /> creando patrimonio.</h4>
        </div>
    </section>


    <section className="clients section ">
        <div className="container ">
            <header className="section-header wow fadeInRight ">
                <strong className="fade-title-right ">Trayectoria</strong>
            </header>

            <section className="blog-details " style={{marginBottom: "3.3em "}}>
                <div className="container ">
                    <div className="row ">
                        <div className="col-primary col-md-8 ">
                            <article className="post " style={{textAlign:"left"}}>

                                <p>Somos una compañía desarrolladora inmobiliaria que nació en 1992. Contamos con una amplia experiencia en el mercado inmobiliario argentino. Hemos participado en el desarrollo de más de <strong>1.000.000 m2</strong>                                            y administrado proyectos por más de <strong>U$S 1.000 millones</strong>, entre los más destacados: el Hotel Hilton de Puerto Madero, Torre Bouchard Plaza, Edificio Samsung, Edifcios River View, Palacio Paz XXI,
                                    Edificio La Rural entre otros.</p>

                            </article>
                        </div>

                    </div>
                </div>
            </section>

            <div className="section-content " style={{marginTop: "3.1em"}}>
            <Link to={settings.base_url + "nosotros"} className="btn btn-shadow-2 ">Conocenos <i className="icon-next "></i></Link>
        </div>
    </div>
    </section>


    </>

  )
}

export default About;
