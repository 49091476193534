
import { useSelector } from "react-redux";
import { getVideo } from "../../functions/functions_dev";
import { searchTour } from "../../functions/functions_property";

const Video = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

  // Render
  return data ? 
    <>
    { data?.videos ? 
        <>
        {getVideo(data.videos,"youtube") ? 
            <section id="video" style={{marginTop: "3em", position: "relative"}}>
                <div class="container" style={{margin: "auto", textAlign: "center"}}>
                    <h2 style={{textAlign: "center", marginBottom: "1em", color: "#525252"}}>Video</h2>
                </div>
                <iframe className="video-development" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" type="text/html" src={getVideo(data.videos,"youtube").player_url + "?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0" + "&origin=https://youtubeembedcode.com"}><div><small><a href="https://youtubeembedcode.com/nl/">youtubeembedcode nl</a></small></div><div><small><a href="https://flickrembedslideshow.com">Flickr embed</a></small></div></iframe>
                <header class="container section-header wow fadeInRight " style={{position:" absolute",top:"0",left: "230px"}}>
                    <strong class="fade-title-left ">Video</strong>
                </header>
            </section> : ''}
        {searchTour(data.videos) ? 
            <section id="tour" style={{marginTop: (getVideo(data.videos) ? "8em" : "3em"), position: "relative"}}>
                <div class="container" style={{margin: "auto", textAlign: "center"}}>
                    <h2 style={{textAlign: "center", marginBottom: "1em", color: "#525252"}}>Tour 360</h2>
                </div>
                <iframe className="video-development" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" type="text/html" src={searchTour(data.videos).player_url}><div></div></iframe>
                <header class="container section-header wow fadeInRight " style={{position:" absolute",top:"0",right: "230px"}}>
                    <strong class="fade-title-right text-nowrap">Tour 360</strong>
                </header>
            </section> : ''}
        </>
    : null }
    </>
     : (
      <div className="loading-section">
        <div className="lds-dual-ring"></div>{" "}
      </div>
    );
};

export default Video;
