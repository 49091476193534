import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";
import { getTextShort,getDateTime } from '../../functions/functions_novelties'
   
const Blogs = () => {

    const dispatch = useDispatch()
    
    const settings = useSelector((store) => store.settings);
    const news = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    const loading_grid = useSelector((store) => store.novelties.loading_grid);

    useEffect(() => {
        dispatch(getFiltersNoveltiesAction())
    }, [])

    return tags || loading_grid ? (
        <section class="blog-list novelty-section">
        <div class="container">
            {console.log(news)}
            {news && !loading_grid ? 
                news.length > 0 ?
                    news.sort((a,b)=>b.position-a.position).map((item, index) => (
                        <article class="blog" key={index}>
                            <div class="row">
                                <div class="blog-thumbnail col-md-8">
                                    <div class="blog-thumbnail-bg col-md-8 visible-md visible-lg" style={{backgroundImage: "url(" + item.image + ")"}}></div>
                                    <div class="blog-thumbnail-img visible-xs visible-sm"><img alt="" class="img-responsive" src={item.image} /></div>
                                </div>
                                <div class="blog-info col-md-4">
                                        {item.tags.map((tag, index) => (
                                            <span className="tag small" key={index} style={{marginRight:"5px"}} >{tag.name}</span>

                                        ))}
                                    <h3 class="blog-title">
                                        <Link to={settings.base_url + "novedades/" + item.id + "/" + item.slug} > {item.title} </Link>
                                    </h3>
                                    <p>{item.headline}</p>
                                    <div class="blog-meta">
                                        {/* <div class="author">
                                            publicado por <a>{item.creator.first_name ? item.creator.first_name  + " " + item.creator.last_name : 'Anonimo'} </a>
                                        </div> */}
                                        { (item?.author_choices && (item?.author_choices !== 'unspecified'))
                                            ? <div class={'author ' + item?.author_choices}>
                                                publicado por <a>{item?.author}</a></div>
                                            : null }
                                        <div class="time">{getDateTime(item.date_published)}</div>
                                    </div>
                                    <div class="text-right"><Link to={settings.base_url + "novedades/" + item.id + "/" + item.slug} class="read-more">Leer nota completa</Link></div>
                                </div>
                            </div>
                        </article>
                    )):
                    <h4 style={{fontSize:"1rem"}}>
                        No existen novedades relacionadas con el filtro de búsqueda seleccionado
                    </h4>
        :
            <>
                <div class="loader relative">
                    <div class="page-lines">
                        <div class="container">
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                        </div>
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                        </div>
                        <div class="col-line col-xs-4">
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                        </div>
                    </div>
                    <div class="loader-brand">
                        <div class="sk-folding-cube">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                        </div>
                    </div>
                </div>
            </>
        }
        </div>
    </section>
    ):''
}

export default Blogs