import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag} from '../../functions/functions_dev'
import ReactGA from 'react-ga';
import useGAEventTracker from "../../functions/useGAEventsTracker";
import { getFrontCover } from "../../functions/functions_property";

ReactGA.initialize('UA-227716759-2');

const Banner = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);
//   const GAEventsTracker = useGAEventTracker("External Links - Emprendimiento");

  const dispatch = useDispatch();


  
//   useEffect(() => {
//     data && getTour(data.videos)
//   },[data])

//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   },[])


  const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 200, behavior: 'smooth'});
  }

  // Render
  return data ? (
      <section class="bannerDevelopment" id="BannerDevelopment">
          <div className="bannerBackground" style={{'backgroundImage':"url(" + (getFrontCover(data.photos) ? getFrontCover(data.photos).original : "")}}></div>
          <div className="container">
              <h2 class="title_development text-center">{data.publication_title.split(".")[0]}</h2>
                {/* <div className="divItemsDevelopment">
                {
                    data.publication_title.split(".").map((element,index) => (
                      index > 0 && element !== '' && element !== '.' > 5 &&
                      <div className="ItemsDevelopment">
                        <i className={"icon-tilde_red " +( data ? 'text-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')}></i>
                        <p>{element}.</p>
                      </div>
                    ))
                  }
                </div>
              {
              <button onClick={() => scrollTo("contacto")} style={{display:"flex",alignItems:"center"}} className={"mt-3 btn " +( data ? 'bg-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')}>Contactar</button>
              } */}
          </div>
      </section>
    ) : (
      <div className="loading-section">
        <div className="lds-dual-ring"></div>{" "}
      </div>
    );
};

export default Banner;
