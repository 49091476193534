import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentsAction } from '../redux/developmentsDucks';
import {clearNoveltyAction} from '../redux/noveltiesDucks';
// -- Componentes -- //

import Main from '../components/inicio/main'
import About from '../components/inicio/about'
import Projects from '../components/inicio/projects'
import Red_i from '../components/inicio/red-i'
import Blogs from '../components/inicio/blogs'
import Contact from '../components/form'
import BannerTOP from '../components/Modules/MediaPush/BannerTop';

function Inicio(){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const data_dev = useSelector((store) => store.developments.developments).data;

    useEffect(() => {
        const defaultSettings = () => {
            dispatch(clearNoveltyAction())
            dispatch(getDevelopmentsAction())
            document.title = settings.seo.title
            window.scrollTo(0, 0);
        }
        defaultSettings()
    }, [])

    return (
        <div className="Inicio">
            <div className="layout">
                <BannerTOP page="inicio" />
                <Main />
                <div className="content">
                    <About />
                    <Projects />
                    <Red_i />
                    <Blogs />
                    <Contact />
                </div>
            </div>
        </div>
    )
}
export default Inicio
