import Aos from "aos";
import 'aos/dist/aos.css';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getNoEnvDescription, getShortNoEnvDesc, getPhotosNoEnv } from "../../functions/functions_dev";

const Description = () => {
  
  const { data } = useSelector((store) => store.developments.development);

  useEffect(() => {
    Aos.init({ duration : 2000})
  }, [])

  return data ? (
    <>
    { (getNoEnvDescription(data?.description)?.length > 0 && getPhotosNoEnv(data.photos)?.length > 0) ?
      <section className="project-details" style={{position: "relative"}}>              
        <header className="container section-header wow fadeInLeft " style={{position: "absolute",left: "20%",top: "80px"}} >
            <strong className="fade-title-left ">Descripción</strong>
        </header>
        <div style={{maxWidth: "600px",margin: "auto",marginTop: "5em",marginBottom: "5.5em"}} className="title-section">
            <h2 style={{textAlign: "center", marginBottom: "1em"}}>Descripción</h2>
        </div>
        <div className="container">
        { getShortNoEnvDesc(data.description).map((d,i) => (
         <>{getPhotosNoEnv(data.photos)[i] && d?.length > 0 ?
              <div className="project-details-item container">
                <div className="row">
                <div className={"project-details-info wow " + ((i%2 === 0) ? "fadeInLeft bg-yellow-theme" : "fadeInRight")} data-aos={(i%2 === 0) ? "fade-right" : "fade-left"} data-aos-offset="200" data-aos-easing="ease-in-sine">
                    <p className="project-details-descr">{d + ((d.substring(d?.length - 1).match('[A-Za-z]')) || (d.substring(d?.length - 1) !== '.') ? "." : "")}</p>
                </div>
                <div className={"project-details-img " + ((i%2 === 0) ? "col-md-8 col-md-offset-4" : "col-md-8")}>
                    <img alt="" className="img-responsive" src={getPhotosNoEnv(data.photos)[i] ? getPhotosNoEnv(data.photos)[i].original : ''} />
                </div>
                </div>
              </div> : null}
            </>))}
        </div>
      </section>
      : null }
    </>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Description;
