import React , {useEffect} from 'react'


// -- Componentes -- //
import Main from '../components/contacto/main'
import Details from '../components/contacto/details'
import Objects from '../components/contacto/objects'
import Contact from '../components/form'
import BannerTOP from '../components/Modules/MediaPush/BannerTop'




function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Contacto(){

    ScrollToTopOnMount()
    return(
        <div className="contacto">
          <div className="layout">
            <BannerTOP page="contacto" />
            <Main />
            <div className="content">
              <Details />
              <Objects />
              <Contact />
            </div>
          </div>
        </div>
    )
}

export default Contacto
