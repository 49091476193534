// constantes
const dataInicial = {
    name:'Real Estate Developers',
    short_name:'red',
    // base_url:'/clientes/red-react/',
    base_url:'/',
    client_id:8,
    social:
    {
        facebook:'',
        instagram:'',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['000-00000' , '000-000000'],
        whatsapp:{link:'+5491170451111',visual:'+54 11 4777.2100'},
        mail:'contactenos@redsa.com.ar'
    },
    location:
    {
        address:'Dirección',
        region:'Ciudad, Argentina',
        cp:'0000'
    },
    keys:{
        captcha:'6LeOjZocAAAAAASwfNNNJdf60E3KoQhuyVngYiY-',
        tokko:'358fb972eb932f24f6d53183c57303e1200855b1',
        google_tgm:'',
        mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZDJhMmZkZWMxZmJkZWE3OTA2N2U5M2QxZjkzY2YzMmE3NDhkZjk4Mzc2ZmFiNzAyZTlmMmE5MjQ3NWQ0MGYxNjdmOGFjN2FhZjc3ZTI3MWQiLCJpYXQiOjE2NjY4MDA2OTIsIm5iZiI6MTY2NjgwMDY5MiwiZXhwIjoxNjk4MzM2NjkyLCJzdWIiOiIxMTgiLCJzY29wZXMiOlsicG9zdHMiLCJjYXRlZ29yaWVzIiwidGFncyIsInVzZXJzIiwiY2xpZW50cyIsImZpbGVzIiwiZ3JvdXBzIl19.mfU7XWvn6BpcUh-rp8uP23X2kLjk0n3NlLsWdjYhru_G5dfXWOWUp8beyxqltV9mYoVp1t6KUjahdyDr8QCcBVx2LuDZMWWr4YBs6LypKNOgrFQpWOeWHrZRE88in08G6sLoPlPsdKIFgdUgIfzgStTq0iVNgnl1f6au97gDfZq-yFBCCp3lrypAGMqZLT3jpZtGLyuGHIMF--WhE2Mw-fbwEl1-HgeA6lsEANq_6LVF_wvTO65PSldPG5kGWNFAansUxOnrlTq0WdKvLSQPcNaTYOy_Z2-C0Cy5ZBPO1SEavWS4da3164YOHQMD1WyrU2_i2Dy_qCySmFx90O0fJEqT45UnjnLvdwrS1kkrDLteAofwSLRCmUksoV5ysbhhIfwfM0zop83gUa_-p596GdbEkScqSl-p0hQVPd_uO5tJTMY9Zxww6YYhM4usCGgdnWdflWSJ-0jBs471klUBF750imHiBaRcu9b47Ua-3iM5B4LeRWBboRh7a1ab5MX9KSUO5AHxi-ZZ66Bv7fBmfFVMXH15fey5I_ZEfAuCBeWL2oF2MeatBpf6quxqTop-mUeuTN-ywgzBcdKCgwM4n2zwAkrpot66IT4AZuOI33DqQH3CrLINryHUG7j6vxDrMQAddxW5Mc3QImEHd5Cgw2vaWhzbzVx75e9N0TrBPpI",
        google_map:'AIzaSyC8D0QNjpuYCRuWzXVFdXyyxPohKh7LiHI',
        ga_analytics_mediacore:'G-37T1R5P56S'

    },
    seo:{
        title:'RED - Real Estate Developers | Conocemos el mercado argentino de real estate.',
        description:'Somos una compañía desarrolladora inmobiliaria que nació en 1992. Hemos participado en el desarrollo de más de 1.000.000 m2 y administrado proyectos por más de U$S 1.000 millones, entre los más destacados: el Hotel Hilton de Puerto Madero, Torre Bouchard Plaza, Palacio Paz XXI, Edificio La Rural entre otros.',
        url:'http://www.redsa.com.ar/',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}