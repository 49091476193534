import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag, group_Unities, findEnvironments, findSurfaces, findPrices, totalUnities, findConstructionDate, getCustomTags, getCustomTagsFiltred} from '../../functions/functions_dev'
import BreadCrumbs from "../breadcrumbs";
import ReactGA from 'react-ga';
import useGAEventTracker from "../../functions/useGAEventsTracker";
import CustomTag from './customTag';

ReactGA.initialize('UA-227716759-2');

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);
  const unities = useSelector((store) => store.developments.units_development)?.data;
  const group = unities ? group_Unities(unities?.objects) : null; // {floor: 'Piso PB y 1 Contrafrente ', unities: Array(1)}
  const GAEventsTracker = useGAEventTracker("External Links - Emprendimiento");

  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [tour,setTour] = useState()
  const [datos, setDatos] = useState({
      nombre:'',
      email:'',
      empresa:'',
      phone:'',
      text:'',
  })

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])
  
  useEffect(() => {
    data && getTour(data.videos)
  },[data])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])

  const getTour = (videos) => {
    setTour(videos.filter(element => element.provider === 'roundme')[videos.filter(element => element.provider === 'roundme').length - 1])
    return null
  }

  const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 200, behavior: 'smooth'});
  }

  // Text title content
  const environments = findEnvironments(unities?.objects);
  const unitiesFrom = environments[0];
  const unitiesTo = environments[environments?.length -1];
  
  const surfaces = findSurfaces(unities?.objects)[0];
  const unitiesM2From = surfaces && surfaces[0];
  const unitiesM2To = surfaces && surfaces[surfaces?.length -1];
  const formatedTag = (t) => t.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ','')
  const formatText = (t) => {
    if(t?.charAt(0) === '.') {
      return t.replace('. ','').trim() + "."
    }
    return t
  }

  // Render
  return (data && unities?.objects) ? (
    <section class="section" id="proyectos" style={{marginTop: "10.1em "}}>
      <div className="container position-relative">
        <div className="row">
          <div className="col-12 col-lg-6 text-col">
            <div className="breadcrum-wrapper">
                <BreadCrumbs
                  props={[
                    { name: "Home", route: settings.base_url, location: "" },
                    { name: "Desarrollos", route:  settings.base_url + "desarrollos", location: "" },
                    {
                      name: data.name,
                      route:'',
                      active:true
                    }
                  ]}
                />
            </div>
            <h3 className="title">Unidades de {unitiesFrom} a {unitiesTo} ambientes de {unitiesM2From} a {unitiesM2To} m2</h3>
            <p className="description">{formatText(data.publication_title.replace(data.publication_title.split(".")[0], ''))}</p>
            <div className="row row-details">
              <div className="col-12 col-lg-4 d-flex flex-column">
                <h5 className="text-uppercase">Precio</h5>
                <small>Desde USD {findPrices(group)[0]}</small>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-column">
                <h5 className="text-uppercase">Unidades</h5>
                <small>{totalUnities(group)}</small>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-column">
                <h5 className="text-uppercase">Fecha de entrega</h5>
                <small>{findConstructionDate(unities?.objects)}</small>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 icons-col">
              { getCustomTagsFiltred(data)?.length > 0 ?
                getCustomTagsFiltred(data)?.map((t, i) => (
                      <div className="d-flex item-custom flex-column" key={i}>
                        <CustomTag tag={formatedTag(t.name)} alt={t.name}/>
                        <h5 className="mt-2">{t.name}</h5>
                    </div>
                )) : null }
          </div>
        </div>
        <header className="container section-header wow fadeInRight" style={{position: "absolute", top: "-30px", right: "230px"}}>
          <strong className="fade-title-right">Proyectos</strong>
        </header>
      </div>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
